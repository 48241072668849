<template>
  <div>
    <div class="layoutBorder">
      <div class="layoutHeader">
        <h4>ФИНМОЛЛ – это получение займа на счет клиента в любом банке.</h4>
      </div>
      <div class="layoutBody">
        <p class="desc">Займы онлайн без справок и поручителей. Без скрытых процентов и комиссий. Конфиденциально и безопасно. Заём онлайн на ваш банковский счет и с возможностью получения денег через Платежную систему.</p>
        <p class="">Основные требования к нашим заемщикам:</p>
        <ul>
          <li>- гражданство РФ;</li>
          <li>- возраст старше 21 года;</li>
          <li>- наличие постоянной регистрации в одном из субъектов РФ;</li>
        </ul>
        <p class="desc">Вы можете быть пенсионером или студентом, молодой мамой, иметь постоянный доход – в любом случае, вам не нужно собирать справки и доказывать свою платежеспособность, чтобы подать заявку на заем</p>

        <p>Мобильное приложение позволяет:</p>
        <ul>
          <li>- подать заявку на заем;</li>
          <li>- получать консультации от финансовых операторов;</li>
          <li>- осуществлять досрочное погашение займа;</li>
          <li>- удобно погашать  текущий заем;</li>
          <li>- получать информацию об остатке долга, внесенных платежах и графике платежей</li>
        </ul>

        <p class="desc">
          На сумму от 20 000 до 100 000 рублей, сроком 364 дней.<br>
          Минимальная годовая процентная ставка: 190%<br>
          Максимальная годовая процентная ставка: 206%<br>
          Минимальный период погашения долга:  364 дней<br>
          Максимальный период погашения долга: 364 дня<br>
        </p>

        <p class="desc">
          Расчет стоимости займа производится по формуле:<br>
          Сумма к возврату = Сумма Займа + Сумма Переплаты, где<br>
          Сумма переплаты = Сумма Займа * Период Займа * (Ставка в % / 100)<br>
          Дополнительные комиссии не взимаются.<br>
        </p>

        <p class="desc">
          Пример расчета общей стоимости займа в размере 25 000 рублей на срок 364 дня:<br>
          Процентная ставка за пользование займом 364 дней составляет 0,56% в день.<br>
          Полная стоимость кредита (ПСК) составляет 206% годовых.<br>
          Сумма начисленных процентов за 364 дней пользования займом составляет 34 194.09 рублей.
        </p>

        <p class="desc">
          Погашение регулярными платежами раз в 7 дней:<br>
          Платеж 1: 1 140 рублей<br>
          Платеж 2: 1 140 рублей<br>
          Платеж 3: 1 140 рублей<br>
          Платеж 4: 1 140 рублей<br>
          .....................<br>
          Платеж 51: 1 140 рублей<br>
          Платеж 52:  954.63 рублей<br>
        </p>

        <p class="desc">Общая сумма к погашению - 59 094.63  рублей.</p>
        <p class="desc">Неустойка в случае нарушения срока внесения какого-либо платежа, указанного в графике платежей, отсутствует</p>




      </div>
    </div>
    <div class="layoutBorder">
      <div class="layoutHeader">
        <h4>Информация для заемщика</h4>
      </div>
      <div class="layoutBody">
        <p class="desc">Общество с ограниченной ответственностью
          Микрокредитная компания «ФИНМОЛЛ» / ООО МКК «ФИНМОЛЛ»<br/>
          Запись в государственном реестре МФО № 651403550005541 от 19.08.2014
        </p>
        <a href="tel:88006009990">Телефон: 8 800 600-99-90</a>
        <p class="desc"></p>
        <p class="desc">Правила предоставления микрозаймов - <a href="https://finmoll.ru/documents" target="_blank">
          https://finmoll.ru/documents</a></p>
        <p class="desc"><a href="https://finmoll.ru/" target="_blank">Официальный сайт - www.finmoll.ru</a></p>
        <p class="desc"><a href="https://lk.msk.finmoll.com" target="_blank">Личный кабинет  https://lk.msk.finmoll.com</a></p>
        <p class="desc">ООО МКК «ФИНМОЛЛ» является членом саморегулируемой организации в сфере финансового рынка, объединяющей МФО - СРО «МиР».
          Официальный электронный адрес СРО «МиР»<a href="mailto:info@npmir.ru"  target="_blank">info@npmir.ru.</a>
          Юридический адрес СРО «МиР»: 107078, г. Москва Орликов переулок, д.5, стр.1, этаж 2, пом.11. Запись в реестре членов СРО «МиР» №7700045 от 21.04.2016 г. Официальный сайт СРО «МиР» - npmir.ru</p>

        <p class="desc">Форма для подачи жалоб и обращений на сайте СРО «МиР» - <a
          href="https://npmir.ru/feedback/users"  target="_blank">npmir.ru/feedback/users</a></p>
        <p class="desc">Официальный сайт Банка России - <a href="https://www.cbr.ru/" target="_blank">www.cbr.ru</a></p>
        <p class="desc">Интернет-приемная Банка России - <a href="https://www.cbr.ru/Reception/" target="_blank">https://www.cbr.ru/Reception/</a></p>
        <p class="desc">Государственный реестр микрофинансовых организаций - <a href="https://www.cbr.ru/vfs/finmarkets/files/supervision/list_MFO.xlsx" target="_blank">https://www.cbr.ru/vfs/finmarkets/files/supervision/list_MFO.xlsx</a></p>
        <p class="desc">Официальный сайт финансового уполномоченного - <a href="https://finombudsman.ru/" target="_blank">https://finombudsman.ru/</a></p>
        <p class="desc">Форма для подачи жалоб и обращений в Федеральную службу судебных приставов - <a href="https://fssp.gov.ru/form/" target="_blank">https://fssp.gov.ru/form/</a>
        </p>
      </div>
    </div>
  </div>

</template>

<script>
  export default {
    name: "info"
  }
</script>

<style scoped>
  .desc{margin-bottom: 20px;}

</style>
